body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-background:#22272b;
  --color-on-background:#fff;
  --color-layer-transparent: rgba(0, 0, 0, 0.8);
  --color-surface:#000;
  --color-primary:#000;
  --color-on-primary:#fff;
  --color-on-surface: #fff;
}

.amcharts-main-div a {
  display: none!important;
  opacity: 0!important;
}

.medium-zoom-overlay,
.medium-zoom-image--opened {
  z-index: 999999;
  background: rgba(0,0,0,0.5)
}

.MuiCardActionArea-focusHighlight{
  display: none!important;
}

.knowledge-base-article img{
  max-width: 100%;
  border-radius: 4px;
}